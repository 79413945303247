@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box; /* Asegura que padding y border estén incluidos en el ancho total y alto de los elementos. */
}

body, h1, h2, h3, p, figure, ul, li, table, td, th {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  color: #333;
  line-height: 1.6;
  /* General bg */
  background-color: #000000;
  font-size: 11px;
  background-image: url('/public/Backgrounds/background-1.jpg'); /* Ruta accesible desde public */
  background-size: cover; /* Asegura que la imagen cubra todo el espacio disponible */
  background-position: center; 
  background-repeat: no-repeat;
}

body.light-mode {
  background-color: #ffffff;
  background-image: url('/public/Backgrounds/background-2.jpg');
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  padding-top: 0px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  text-align: center;
}

.title {
  font-size: 24px; /* Responsive font size */
  color: #333;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px; /* Reduced spacing for better readability */
  text-align: center;
  margin: 10px auto;
  margin-top: 0px;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif; /* Modern, sans-serif font */
  background: linear-gradient(45deg, #0B71B1, #3FADF2); /* Updated vibrant gradient */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  text-shadow: 1px 1px 4px rgba(155, 154, 154, 0.25); /* Subtle text shadow for depth */
  animation: fadeInTitle 0.5s ease-in-out;
}

@keyframes fadeInTitle {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

.tutorial-title {
  font-size: 24px; /* Responsive font size */
  color: #333;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px; /* Reduced spacing for better readability */
  text-align: center;
  margin: 10px auto;
  margin-top: 0px;
  margin-bottom: 15px;
  font-family: 'Poppins', sans-serif; /* Modern, sans-serif font */
  background: linear-gradient(45deg, #0B71B1, #3FADF2); /* Updated vibrant gradient */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  text-shadow: 1px 1px 4px rgba(155, 154, 154, 0.25); /* Subtle text shadow for depth */
  animation: fadeInTutorialTitle 0.5s ease-in-out;
}

@keyframes fadeInTutorialTitle {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Optimize for dark mode with a dynamic color scheme */
:root {
  --subtitle-text-color: #EAEAEA;
  --subtitle-border-color: #297FBA; /* A medium shade between #0B71B1 and #3FADF2 */
  --subtitle-background-color: #1A1A2E; /* Dark background for contrast */
}

/* Apply styles to the subtitle for a modern look */
.subtitle {
  color: var(--subtitle-text-color);
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  font-weight: 100;
}

/* Style the container with a solid border color that matches the title's blue theme */
.subtitle-container {
  border: 2px solid var(--subtitle-border-color);
  padding: 15px;
  max-width: 560px;
  background-color: rgba(26, 26, 46, 0.65);
  border-radius: 8px; /* Rounded corners for a softer look */
  margin: 0px auto; /* Center align the container */
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2); /* Optional: Adds depth */
  margin-bottom: -20px;
  animation: fadeInSubtitle 0.5s ease-in-out;
  box-shadow: 0 2px 16px rgba(255, 255, 255, 0.595);
}

@keyframes fadeInSubtitle {
  from { opacity: 0; transform: translateY(-10px); }
}

.video-container {
  padding-top: 50px;
  padding-bottom: 20px;
  animation: fadeInVideo 0.5s ease-in-out;
}

@keyframes fadeInVideo {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

.use-chordprog {
  background-color: #297FBA; /* Color base, usado si los gradientes no son soportados */
  background: linear-gradient(45deg, #0B71B1, #3FADF2); /* Gradiente de izquierda a derecha */ /* Adjusted to match the new subtitle border color */
  border: none;
  color: #ffffff;
  padding: 12px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  font-family: 'Poppins', sans-serif; /* Consistent font for a cohesive look */
  font-weight: 600; /* Slightly less bold for a modern touch */
  margin: 30px auto;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2); /* Enhanced shadow for more depth */
  border-radius: 8px; /* Increased roundness for a softer, modern edge */
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
  cursor: pointer; /* Indicates the button is clickable */
  margin-top: 20px;
  margin-bottom: 0;
  animation: fadeInButton 0.5s ease-in-out;
  box-shadow: 0 2px 16px rgba(255, 255, 255, 0.595);
}

@keyframes fadeInButton {
  from { opacity: 0; transform: translateY(-10px); }
}

.use-chordprog:hover, .use-chordprog:focus {
  background: linear-gradient(45deg, #2b78a8, #70c0f1); /* Ligeramente más claro o con colores invertidos para el efecto hover */
  /* Lighter shade for hover effect, matching the gradient theme */
  transform: translateY(-2px); /* Subtle lift effect */
  box-shadow: 0 2px 16px rgba(255, 255, 255, 0.595);
}

.use-chordprog:active {
  background-color: #0B71B1; /* Original blue for active state, adding depth */
  transform: translateY(1px); /* Mimics the button being pressed down */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Resets to the original shadow */
}

.header {
  display: flex;
  justify-content: center; /* Centra los elementos horizontalmente */
  align-items: center;
  width: 100%;
  position: relative; /* Permite posicionar absolutamente el menú desplegable en relación con este contenedor */
}

.header .title-and-menu-icon {
  padding-top: 20px;
  display: flex;
  align-items: center;
  position: relative; /* Esta línea es crucial */
}

.title-and-menu-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative; /* Esto asegura que el menú se posicione en relación con estos elementos */
  width: fit-content; /* Ajusta el ancho al contenido dentro del contenedor */
  margin: auto; /* Centrar el contenedor en la cabecera */
}

.menu-icon {
  cursor: pointer;
  font-size: 20px;
  padding: 10px;
  padding-top: 0px;
  color: #BCBCAF;
  animation: fadeInMenuIcon 0.5s ease-in-out;
}

@keyframes fadeInMenuIcon {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

.dropdown-menu {
  position: absolute;
  top: calc(100% - 10px);
  left: 10px;
  z-index: 1000;
  background-color: #4d4d4d;
  color: #BCBCAF;
  box-shadow: 0 2px 6px rgba(255, 255, 255, 0.237);
  font-size: 14px;
  border-radius: 4px;
  width: 200px; /* Tamaño típico de un menú desplegable */
  padding: 5px 0;
}

.menu-option {
  font-weight: normal;
  transition: font-weight 0.2s; /* Transición suave al cambiar el peso de la fuente */
}

.menu-option.active-option {
  font-weight: bold;
}

.menu-option:hover {
  font-weight: bold;
}

.menu-container {
  position: relative;
  display: inline-block; /* Alinea el contenedor con el título */
}

body.light-mode .dropdown-menu {
  background-color: #e1e0e0;
  color: #626262;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.237);
}

.dropdown-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdown-menu ul li {
  padding: 10px 15px;
  cursor: pointer;
  color: inherit; /* Hereda el color del contenedor */
}

.dropdown-menu ul li:last-child {
  border-bottom: none;
}

/* Efecto hover para elementos clickeables */
.dropdown-menu ul li:hover {
  background-color: #5e5e5e; /* Modo oscuro */
  color: #ffffff; /* Opcional: Cambia el color del texto */
}

body.light-mode .dropdown-menu ul li:hover {
  background-color: #d2d1d1; /* Modo claro */
  color: #313131; /* Opcional: Cambia el color del texto */
}

body.light-mode .dropdown-menu li:hover {
  background-color: #d2d1d1; /* Color de fondo más claro para el modo claro */
  color: #313131; /* Asegúrate de que el color del texto tenga suficiente contraste */
}

.table-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  width: 100%;
  max-width: 1700px;
  overflow-x: auto;
  /*Charts title font & Charts font */
  color: #bcbcbc;
  padding: 0px;
  border-radius: 10px; /* Asegura que este valor coincida con el radio de borde deseado */
  box-shadow: 0 2px 6px rgba(255, 255, 255, 0.237);
  max-width: 1150px;
}

body.light-mode .table-container {
  color: #2d2d2d;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.237);
}

.chord-table, .scale-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 0px;
  padding: 0px;
  max-width: 1150px;
  animation: fadeInTables 0.5s ease-in-out;
}

.scale-table{
  margin-top: 0;
}

@keyframes fadeInTables {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

body.light-mode .scale-table {

}

th, td {
  border: 1px solid #404040;
  padding: 3px;
  text-align: center;
  padding-top: 0px;
  padding-bottom: 0px;
  box-shadow: 0px 4px 8px rgba(255, 255, 255, 0.5);
}

body.light-mode th, body.light-mode td {
  border: 1px solid #DDDDDD;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
}

th {
  /* Charts titles bg */
  background-color: #252526;
  font-weight: 700;
}

body.light-mode th {
  background-color: #EAEAEA;
}

td {
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

td:hover {
  color: #ffffff;
  font-weight: bold;
}

body.light-mode td:hover {
  color: #000000;
  font-weight: bold;
}

.chords-cell {
  background-color: #2c2c2d;
  border-top-left-radius: 10px;
}

.scales-cell {
  background-color: #2c2c2d;
  border-top-left-radius: 10px;
}

.selected {
  background-color: #ff6347;
  color: white;
}

.table-container h2 {
  margin-bottom: 0px;
  /* "chords" and "scales" font */
  color: #A4A4A4;
  font-size: 0.9em;
}

.selected {
  background-color: #4CAF50; /* Verde para seleccionado */
  color: white;
}

.compatible {
  /* Estilos para botones compatibles */
}

.incompatible {
  background-color: #ff4a4a; /* Rojo para incompatible */
  color: #bcbcbc;
}

/* Estilos para los acordes arrastrables */
.draggable-chord {
  cursor: grab; /* Cambia el cursor a una mano para indicar que se puede arrastrar */
}

body.light-mode .incompatible {
  background-color: #FFCCCC; /* Un tono de rojo más claro/suave para el modo claro */
}

/* Estilos para la barra donde se soltarán los acordes */
.drop-bar {
  max-width: 1000px; /* El mismo max-width que el de tus tablas */
  width: 100%;
  min-height: 35px;
  background-color: #333333;
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #404040;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Opcional, para coincidir con el estilo de las tablas */
  max-width: calc(100% - 60px); /* Reducir el ancho para acomodar el cesto de basura */
  min-width: 200px; /* Establece un ancho mínimo para la barra */
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(255, 255, 255, 0.237);
}

body.light-mode .drop-bar {
  background-color: #ADD8E6;
  border: 0;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.237);
}

.bar-and-trash-container {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  width: 100%; /* Ocupa todo el ancho disponible */
  max-width: 1150px; /* Ajusta esto al mismo ancho que las tablas */
  margin-top: 3px;
  animation: fadeInBar 0.5s ease-in-out;
}

@keyframes fadeInBar {
  from { opacity: 0; transform: translateY(-10px); }
}

.dropped-chord {
  margin: 3px;
  padding: 3px;
  /* Chords on bar */
  background-color: #565657;
  border: 1px solid #4b4a4a;
  color: #BCBCBC;
  border-radius: 4px;
}

body.light-mode .dropped-chord {
  color: rgb(56, 56, 56);
  background-color: rgb(228, 227, 227);
  border: 1px solid rgb(185, 184, 184);
}

/* Estilos para indicar cuando un elemento está siendo arrastrado sobre la barra */
.drop-bar.over {
  background-color: darkblue;
  color: white;
}

.dropped-chord.dragging {
  opacity: 0.5; /* Estilo para el acorde que se está arrastrando */
}

.play-button {
  /* Estilos para el botón Play */
  margin-right: 6px;
  padding: 4px 8px;
  background-color: #0B71B1;
  color: #BCBCBC;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 10px;
  font-weight: bold;
  outline: none;
}

body.light-mode .play-button {
  color: white;
}

.play-button:hover {
  background-color: #0d5d8f;
}

.active-chord {
  background-color: #0d5d8f; /* Solo para el acorde activo durante la reproducción */
}

body.light-mode .active-chord {
  background-color: #0d5d8f; /* Solo para el acorde activo durante la reproducción */
  color: white;
}

.trash-bin {
  width: 35px;
  height: 35px;
  /* Trash button */
  background-color: #3C3C3D;
  border: 1px solid #404040;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  cursor: pointer;
  margin-left: 6px; /* Espacio entre la barra y el cesto de basura */
  margin-top: 12px;
  box-shadow: 0 2px 6px rgba(255, 255, 255, 0.237);
}

body.light-mode .trash-bin {
  background-color: #F2F2F2;
  border: 1px solid #CCCCCC;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.237);
}

/* Estilos generales para los elementos del menú */
.context-menu li {
  padding: 7px;
  cursor: pointer;
  color: #BCBCAF; /* Color de texto para elementos clickeables en modo oscuro */
  font-size: 13px;
}

body.light-mode .context-menu li {
  color: #626262; /* Color de texto para elementos clickeables en modo claro */
}

/* Efecto hover para elementos clickeables */
.context-menu li:hover {
  background-color: #636262; /* Color de fondo al hacer hover en modo oscuro */
}

body.light-mode .context-menu li:hover {
  background-color: #d2d1d1; /* Color de fondo al hacer hover en modo claro */
}

/* Estilos para el elemento no clickeable */
.context-menu li.non-clickable {
  cursor: default; /* Cambia el cursor para indicar que no es clickeable */
  color: #9B9B9B; /* Color de texto ligeramente más oscuro en modo oscuro */
}

body.light-mode .context-menu li.non-clickable {
  color: #9B9B9B; /* Color de texto ligeramente más claro en modo claro */
}

/* Evitar efecto hover en el elemento no clickeable */
.context-menu li.non-clickable:hover {
  background-color: inherit; /* Mantiene el fondo original */
  color: #9B9B9B; /* Mantiene el color de texto */
}

body.light-mode .context-menu li.non-clickable:hover {
  background-color: inherit; /* Mantiene el fondo original */
  color: #9B9B9B; /* Mantiene el color de texto */
}

.save-button {
  width: 35px;
  height: 35px;
  /* Save button */
  background-color: #3C3C3D;
  border: 1px solid #404040;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  cursor: pointer;
  margin-right: 6px; /* Espacio entre el botón de guardar y la barra */
  margin-top: 12px;
  box-shadow: 0 2px 6px rgba(255, 255, 255, 0.237);
}

body.light-mode .save-button {
  background-color: #F2F2F2;
  border: 1px solid #CCCCCC;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.237);
}

.save-menu {
  position: fixed;
  z-index: 1000;
  background-color: #4d4d4d;
  color: #BCBCAF;
  box-shadow: 0 2px 6px rgba(255, 255, 255, 0.237);
  font-size: 14px;
  margin-top: 0px;
  animation: fadeInSaveMenu 0.5s ease-in-out; /* Smooth fade-in animation */
  text-align: left;
}

@keyframes fadeInSaveMenu {
from { opacity: 0; transform: translateY(-10px); }
to { opacity: 1; transform: translateY(0); }
}

body.light-mode .save-menu {
  background-color: #e1e0e0;
  color: #626262; /* Suponiendo que quieras usar el color rgb(206, 206, 206) convertido a hexadecimal */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.237);
}

.save-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.save-menu ul li {
  padding: 7px;
  cursor: pointer;
}

.save-menu ul li:last-child {
  border-bottom: none;
}

.save-menu ul li:hover {
  background-color: #636262;
}

body.light-mode .save-menu li:hover {
  background-color: #d2d1d1; /* Color de fondo más claro para el modo claro */
  color: #313131; /* Asegúrate de que el color del texto tenga suficiente contraste */
}

:root {
  /* Colores para el modo oscuro */
  --background-color-dark: #1E1E1E;
  --text-color-dark: #EAEAEA;
  /* Colores para el modo claro */
  --background-color-light: #F0F2F5;
  --text-color-light: #333333;
}

/*------------------ Guitar chords ------------------*/

.guitar-chords-container .chord-container {
  display: inline-block; /* Alinea los acordes horizontalmente */
  margin-top: 15px;
  margin-left: 9px;
  margin-right: 9px;
  text-align: center; /* Alinea el texto del nombre del acorde en el centro */
  animation: fadeInGuitarChords 0.5s ease-in-out; /* Smooth fade-in animation */
  box-shadow: 0px 2px 8px rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  margin-bottom: 25px;
}

.light-mode .guitar-chords-container .chord-container {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
}

@keyframes fadeInGuitarChords {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}


.guitar-chords-container .chord-name {
  font-size: 12px; /* Ajusta el tamaño de la fuente según sea necesario */
  color: #BCBCBC; /* Color del texto para el nombre del acorde */
  font-weight: bold;
  align-items: center; /* Corrige la propiedad a 'center' */
  border: 1px solid #727272;
  border-bottom: 0;
  border-radius: 10px 10px 0 0; /* Redondea solo los bordes superiores */
  padding-top: 0px; /* Asegúrate de que el padding sea consistente */
  background-color: rgba(51, 51, 51, 0.65);
}

.guitar-chords-container{
  margin-top: -5px;
}

.guitar-chords-container img {
  height: 120px; /* Altura fija para todas las imágenes */
  width: auto; /* Ancho automático para mantener la proporción */
  border: 1px solid #727272;
  border-radius: 0 0 10px 10px; /* Redondea solo los bordes inferiores */
  padding: 5px; /* Mantiene el padding interno para la imagen */
  border-top: 0;
  padding-top: 0px;
  background-color: rgba(51, 51, 51, 0.65);
  margin-bottom: -6px;
}

.light-mode .guitar-chords-container img {
  filter: invert(1);
  border: 1px solid #828181;
  border-top: 0;
  background-color: rgba(24, 24, 24, 0.65);
}

/*------------------ Piano chords ------------------*/
.piano-chords-container {
  display: flex; /* Esto alinea los contenedores de acordes en línea */
  justify-content: center; /* Centra los contenedores de acordes */
  flex-wrap: wrap; /* Permite que los acordes se ajusten al ancho del contenedor */
  margin-top: 25px;
  margin-bottom: 15px;
}

.piano-chords-container .chord-container {
  display: inline-block; /* Alinea los acordes horizontalmente */
  margin: 5px; /* Añade un margen alrededor de cada contenedor de acorde */
  text-align: center; /* Alinea el texto del nombre del acorde en el centro */
  margin-top: 0px;
  margin-bottom: 10px;
  margin-left: 9px;
  margin-right: 9px;
  animation: fadeInPianoChords 0.5s ease-in-out; /* Smooth fade-in animation */
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(255, 255, 255, 0.5);
}

.light-mode .piano-chords-container .chord-container {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
}

@keyframes fadeInPianoChords {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}
.piano-chords-container .chord-name {
  font-size: 12px; /* Ajusta el tamaño de la fuente según sea necesario */
  color: #BCBCBC; /* Color del texto para el nombre del acorde */
  font-weight: bold;
  border: 1px solid #727272;
  border-bottom: 0;
  border-radius: 10px 10px 0 0; /* Redondea solo los bordes superiores */
  padding-top: 5px; /* Asegúrate de que el padding sea consistente */
  background-color: rgba(51, 51, 51, 0.65);
}

.light-mode .chord-name {
  color: #3d3c3c;
  background-color: rgba(231, 230, 230, 0.65);
}

.piano-chords-container img {
  height: 90px; /* Altura automática para mantener la proporción */
  width: auto; /* Ajusta el ancho según sea necesario */
  border: 1px solid #727272;
  border-radius: 0 0 10px 10px; /* Redondea solo los bordes inferiores */
  padding: 10px; /* Mantiene el padding interno para la imagen */
  border-top: 0;
  padding-top: 0px;
  background-color: rgba(51, 51, 51, 0.65);
  margin-bottom: -6px;
}

.light-mode .piano-chords-container img {
  filter: invert(1) hue-rotate(180deg) saturate(150%) brightness(90%);
  border: 1px solid #828181;
  border-top: 0;
  background-color: rgba(0, 0, 0, 0.65);
}

.drop-highlight {
  margin-right: 20px;
}

.theme-switcher-container {
  display: flex;
  justify-content: center; /* Centra el contenedor en el li si es necesario */
}

.notation-switcher-container {
  display: flex;
  justify-content: center; /* Centra el contenedor en el li si es necesario */
}

.theme-switcher {
  display: flex;
  gap: 10px;
}

.notation-switcher {
  display: flex;
  gap: 10px;
}


.theme-option {
  cursor: pointer;
  transition: color 0.3s ease; /* Transición suave para el cambio de color */
}

/* Estilo :hover para los elementos theme-option */
.theme-option:hover {
  color: #297FBA; /* Color cuando se hace hover, elige el que prefieras */
}

.notation-option {
  cursor: pointer;
  transition: color 0.3s ease; /* Transición suave para el cambio de color */
}

/* Estilo :hover para los elementos theme-option */
.notation-option:hover {
  color: #297FBA; /* Color cuando se hace hover, elige el que prefieras */
}

.contact-popup {
  background-color: transparent;
  width: fit-content;
  max-width: 600px;
  border-radius: 10px;
  height: fit-content;
}

.contact-popup-overlay {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-popup-content {
  background-color: #353537;
  margin: auto;
  padding: 20px;
  border: 2px solid #404040;
  width: fit-content;
  max-width: 80%;
  border-radius: 10px;
  font-size: 14px;
  color: #BCBCBC;
}

.light-mode .contact-popup-content{
  background-color: #e7e5e5;
  color: #1c1c1c;
  border: 2px solid #565656;
}

.close-popup {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-popup:hover,
.close-popup:focus {
  color: #7a7a7a;
  text-decoration: none;
  cursor: pointer;
}

/*Table corners rounded*/

.chord-table, .scale-table {
  border-collapse: separate; /* Esto es necesario para aplicar border-radius en tablas */
  border-spacing: 0;
  border-radius: 10px; /* Ajusta el radio de las esquinas según prefieras */
  overflow: hidden; /* Asegura que el contenido no desborde los bordes redondeados */
}

.chord-table tr:first-child th:last-child,
.scale-table tr:first-child th:last-child {
  border-top-right-radius: 10px; /* Ajusta según el border-radius de la tabla */
}

/* Ajusta las esquinas inferiores */
.chord-table tr:last-child td:first-child,
.scale-table tr:last-child td:first-child {
  border-bottom-left-radius: 0px; /* Asegúrate de que este valor coincida con el `border-radius` de tu tabla */
}

.chord-table tr:last-child td:last-child,
.scale-table tr:last-child td:last-child {
  border-bottom-right-radius: 10px; /* Ajusta según el border-radius de la tabla */
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader {
  border: 4px solid #b2b1b1; /* Light grey */
  border-top: 4px solid #0B71B1; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

.bpm-container {
  padding: 0;
  display: flex;
  align-items: center; /* Alinea verticalmente los elementos internos */
  background-color: transparent; /* Fondo para simular que es una sola caja */
  border-radius: 4px; /* Bordes redondeados para el contenedor */
  padding-left: 5px; /* Espacio interno para que no esté pegado al borde */
  margin-right: 8px;
  justify-content: center; /* Centra horizontalmente los elementos internos */
  margin: auto; /* Centra el contenedor en su contenedor padre */
  width: 100%;
}

.bpm-container:hover {
  background-color: #494848;
}

body.light-mode .bpm-container:hover {
  background-color: #cfcdcd;
}

.bpm-input {
  padding: 0;
  margin: 0;
  margin-right: 0px; /* Espacio entre el input del BPM y el botón Play */
  padding: 0; /* Ajustado para simplificar la declaración */
  border: none; /* Elimina el borde para fusionarlo con el label */
  background-color: transparent; /* Fondo para simular continuidad */
  color: #BCBCBC; /* Color del texto */
  outline: none; /* Elimina el contorno al enfocar */
  border-top-right-radius: 4px; /* Redondea el borde superior derecho */
  border-bottom-right-radius: 4px; /* Redondea el borde inferior derecho */
  display: flex;
  align-items: center; /* Alinea los elementos hijos verticalmente al centro */
  /* Si también quieres centrarlos horizontalmente, puedes añadir: */
  justify-content: center;
  line-height: normal;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 16px;
  width: 47px;
}

.bpm-label {
  margin-right: 5px; /* Espacio entre el label y el input */
  color: #BCBCBC; /* Color del texto */
  font-size: 13px; /* Tamaño de la fuente */
  background-color: transparent; /* Fondo para simular continuidad */
  font-weight: 525; /* Peso de la fuente */
  padding: 0; /* Espaciado interno para mantener consistencia con el input */
  border-top-left-radius: 4px; /* Redondea el borde superior izquierdo */
  border-bottom-left-radius: 4px; /* Redondea el borde inferior izquierdo */
  display: flex;
  align-items: center; /* Alinea los elementos hijos verticalmente al centro */
  /* Si también quieres centrarlos horizontalmente, puedes añadir: */
  justify-content: center;
  line-height: normal;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 16px;
}

/* Asegúrate de ajustar para el modo claro si es necesario */
body.light-mode .bpm-container {
  background-color: transparent; /* Ajusta según tu diseño para modo claro */
}

body.light-mode .bpm-input,
body.light-mode .bpm-label {
  background-color: #transparent; /* Ajusta para el modo claro */
  color: #515050; /* Color del texto para el modo claro */
}

@media (max-width: 649px) {
  .title {
    font-size: 2em;
    padding: 0px;
    margin-top: -2px;
  }

  .App {
    padding: 10px;
    padding-top: 0px;
  }

  .table-container {
    width: 100%;
    max-width: none;
  }

  .chord-table, .scale-table {
    width: 100%;
  }

  th, td {
    padding: 1px;
    font-size: 0.48em;
  }

  .bar-and-trash-container, .drop-bar {
    width: 100%;
    min-height: 25px; /* Ajuste opcional para la altura de la barra si es necesario */
  }

  /* Ajusta el tamaño de los botones de guardar y basura */
  .save-button, .trash-bin {
    width: 25px; /* Ajustado a 25px como ejemplo, ajusta según necesidad */
    height: 25px; /* Ajustado a 25px como ejemplo, ajusta según necesidad */
    font-size: 0.7em; /* Ajusta el tamaño de fuente/icono dentro de los botones si es necesario */
    border-radius: 5px;
  }

  .play-button {
    /* Estilos para el botón Play */
    margin-right: 6px;
    padding: 4px 8px;
    background-color: #0B71B1;
    color: #BCBCBC;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 8px;
    font-weight: bold;
  }

  .dropped-chord {
    margin: 2px; /* Reduce el margen para ahorrar espacio */
    padding: 2px 4px; /* Ajusta el padding para reducir el tamaño general */
    font-size: 8px; /* Reduce el tamaño de la fuente para adaptarse a pantallas más pequeñas */
    min-height: 20px; /* Opcional: ajusta la altura mínima si es necesario */
    border-radius: 3px; /* Opcional: ajusta el radio del borde para mantener la proporción */
  }

  .guitar-chords-container img {
    height: 90px; /* Altura fija para todas las imágenes */
    width: auto; /* Ancho automático para mantener la proporción */
  }

  .piano-chords-container img {
    height: 70px; /* Altura automática para mantener la proporción */
    width: auto; /* Ajusta el ancho según sea necesario */
  }

  .dropdown-menu {
    width: 35vw; /* El menú ocupará el 80% del ancho de la ventana */
    left: 50%; /* Posicionamos el menú a la mitad de la pantalla */
    transform: translateX(-50%); /* Centramos el menú horizontalmente */
  }

  .video-container {
    overflow: hidden;
    aspect-ratio: 11/12;
    position: relative;
    width: 100%;
    margin-top: -10px;
  }
  
  .video-container iframe {
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
  }
 
  .use-chordprog {
    width: 100%;
    align-items: center;
    margin: 20px auto;
    margin-top: 10px;
  }

  .chord-table, .scale-table {
    border-spacing: 1px;
  }
}